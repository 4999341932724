<template>
  <div class="maintenance-container">
      <img src="./../assets/Logo_FacilImmo_300.jpg" alt="logo Facil'Imo" />
      <h1>Site Internet en maintenance</h1>
  </div>
</template>

<script>

export default {
  name: 'Maintenance',

}
</script>

<style lang="scss" scoped>
    

    .maintenance-container {
        min-height:100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .maintenance-container img {
        width:300px;
        height:auto;
        display: block;
        margin-bottom: 60px;
    }

    .maintenance-container h1 {
        margin:0;
        text-align: center;
        font-size:25px;
        color:$main-color;
        font-family: 'Montserrat', sans-serif;
    }
</style>
